<template>
  <v-container id="teams" class="ma-auto" fluid>
    <under-development></under-development>
  </v-container>
</template>
<script>
import underdevelopment from "../UnderDevelopment.vue";
export default {
  name: "Team",
  data() {
    return {};
  },
  components: {
    'under-development':underdevelopment
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
 
