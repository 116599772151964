<template>
  <v-card flat class="text-center d-flex justify-center align-center ma-auto custom-off-white" style="height: fit-content">
    <v-row class="pa-0 ma-0">
      <v-col cols="11" sm="10" md="8" lg="8" class="ma-auto" style="z-index: 1">
        <v-row class="ma-3">
            <v-col cols="12" class="pa-0 ma-0">
                <v-icon color="custom-accent lighten-1" size="100">mdi-hammer-wrench</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
                <div class="text-h2 secondary--text font-weight-medium mb-1">
                Oops!
                </div>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
                <span class="text-h5 secondary--text">{{$t("underdevelopmentdesc")}}</span>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 mt-7">
                <v-btn class="custom-accent lighten-1 text-center" dark depressed @click="$navigateToPath('/')"><v-icon>mdi-home</v-icon><span class="ma-0 pa-0 mt-1">{{ $t("home") }}</span></v-btn>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "UnderDevelopment",
  data() {
    return {};
  },
  components: {},
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
 
